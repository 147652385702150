<template>
  <Drawer title="日志信息" v-model="isShow" width="600" scrollable>
    <Row class="text-cell">
      <Col class="title" span="3">缓存key</Col>
      <Col class="value" span="20">{{ obj.Key }}</Col>
    </Row>
    <Row class="text-cell">
      <Col class="title" span="3">更新时间</Col>
      <Col class="value" span="20">{{ obj.Date }}</Col>
    </Row>
    <Row class="text-cell">
      <Col class="title" span="3">缓存内容</Col>
      <Col class="value" span="20">{{ obj.Data }}</Col>
    </Row>
  </Drawer>
</template>
<script>
import api from "@/api";
export default {
  name: "cache-detail-drawer",
  data() {
    return {
      isShow: false,
      obj: { Key: "", Date: "", Data: "" },
    };
  },
  watch: {
    isShow: function (val) {
      if (val) {
        this.GetRedis();
      }
    },
  },
  methods: {
    async GetRedis() {
      this.tableLoading = true;
      try {
        const res = await api.GetRedis({
          key: this.obj.Key,
        });
        if (res.Status === 100) {
          this.obj.Data = res.Data;
          this.tableLoading = false;
        } else {
          this.$Message.error(res.Msg);
        }
      } catch (error) {
        this.$Notice.error({
          title: "错误",
          desc: error,
        });
        this.tableLoading = false;
        // TODO: 记录错误日志
      }
    },
  },
  computed: {},
};
</script>
