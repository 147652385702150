<template>
  <Modal
    v-model="isShow"
    title="修改缓存"
    loading
    width="600"
    class-name="i-modal"
    :maskClosable="false"
    @on-ok="handleSave"
  >
    <Form :model="obj" ref="cacheForm" :label-width="80" :rules="validateRules">
      <Row :gutter="16" prop="CacheName">
        <Col span="12">
          <FormItem label="缓存名称:" prop="CacheName"
            ><Input v-model="obj.CacheName"
          /></FormItem>
        </Col>
        <Col span="12" prop="CacheKey">
          <FormItem label="缓存编码:" prop="CacheKey"
            ><Input v-model="obj.CacheKey"
          /></FormItem>
        </Col>
      </Row>
      <Row :gutter="16">
        <Col span="12" prop="CacheTime">
          <FormItem label="缓存时间:"
            ><Input v-model="obj.CacheTime"
          /></FormItem>
        </Col>
        <Col span="12">
          <FormItem label="时间单位:" prop="CacheUnit">
            <Select transfer v-model="obj.CacheUnit">
              <Option :value="0">秒</Option>
              <Option :value="1">分</Option>
              <Option :value="2">时</Option>
              <Option :value="3">日</Option>
              <Option :value="4">月</Option>
              <Option :value="5">年</Option>
            </Select>
          </FormItem>
        </Col>
      </Row>
      <Row :gutter="16">
        <Col span="12">
          <FormItem label="是否启用:" prop="Status">
            <Select transfer v-model="obj.Status">
              <Option :value="10">启用</Option>
              <Option :value="20">禁用</Option>
            </Select>
          </FormItem>
        </Col>
        <Col span="12">
          <FormItem label="创建时间:"
            ><Input v-model="obj.CreateDate" disabled
          /></FormItem>
        </Col>
      </Row>
    </Form>
    <template slot="footer">
      <Button type="default" @click="handleCancel">取消</Button>
      <Button type="primary" :loading="isUploading" @click="handleSave"
        >保存</Button
      >
    </template>
  </Modal>
</template>

<script>
import api from "@/api";
export default {
  name: "cache-edit",
  data() {
    return {
      isShow: false,
      isUploading: false,
      obj: {},
      validateRules: {
        CacheName: [
          { required: true, message: "缓存名称不能为空 ", trigger: "blur" },
        ],
        CacheKey: [
          { required: true, message: "缓存编码不能为空 ", trigger: "blur" },
        ],
      },
    };
  },
  watch: {},
  methods: {
    async handleSave() {
      this.$refs.cacheForm.validate(async (valid) => {
        if (valid) {
          try {
            this.isUploading = true;
            const res = await api.SaveDataCache(this.obj);
            if (res.Status === 100) {
              this.isUploading = false;
              this.$emit("on-success", res.Data);
              this.$Message.success("操作成功");
              this.isShow = false;
            } else {
              this.$Notice.error({
                title: "错误",
                desc: res.Msg,
              });
            }
          } catch (error) {
            console.log(error);
            this.$Notice.error({
              title: "错误",
              desc: error,
            });
            this.isShow = false;
            this.isUploading = false;
          }
        } else {
          this.$Message.error("填写信息有误！");
        }
      });
    },
    handleCancel() {
      this.$refs.cacheForm.resetFields();
      this.isShow = false;
    },
  },
  computed: {},
};
</script>
