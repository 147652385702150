<template>
  <Layout class="main-container">
    <!--    搜索块-->
    <Form
      ref="SearchForm"
      class="search-form-container"
      :model="params"
      label-position="left"
      :label-width="70"
    >
      <div class="search-form-container__content">
        <Row :gutter="16">
          <Col span="6">
            <FormItem label="缓存名称:" prop="CacheName">
              <Input
                placeholder="请输入缓存名称"
                v-model="params.CacheName"
                clearable
              />
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem label="缓存编码:" prop="CacheKey">
              <Input
                placeholder="请输入缓存编码"
                v-model="params.CacheKey"
                clearable
              />
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem label="状态:" prop="Status">
              <Select clearable v-model="params.Status" placeholder="状态">
                <i-option :value="-1">全部</i-option>
                <i-option :value="0">启用</i-option>
                <i-option :value="1">禁用</i-option>
              </Select>
            </FormItem>
          </Col>
        </Row>
      </div>
      <Row type="flex" justify="space-between" align="bottom" class="mt5">
        <Col span="12" style="text-align: left">
          <Button
            type="success"
            icon="md-add-circle"
            v-if="$canAction(['Sys_Cache_Edit'])"
            @click="openCacheAdd"
          >
            新增
          </Button>
        </Col>
        <Col span="12" style="text-align: right">
          <span
            class="search-form-container__arrow_btn"
            @click="$handleHiddenSearch($el)"
          >
            收起
            <Icon type="ios-arrow-up" />
          </span>
          <Button
            class="ml2 search-form-container__button"
            type="primary"
            ghost
            icon="ios-search"
            @click="handleSearch"
          >
            搜索
          </Button>
          <Button
            class="ml2 search-form-container__button"
            @click="handleReset"
          >
            重置
          </Button>
        </Col>
      </Row>
    </Form>
    <!--    table-->
    <Table
      stripe
      class="mt10 main-table"
      @on-sort-change="sortChange"
      :loading="tableLoading"
      :height="tableHeight"
      ref="dataTable"
      :columns="columns"
      :data="resObj.DataList"
      highlight-row
      size="small"
    >
      <template slot="Action" slot-scope="{ row }">
        <Button
          class="ml2"
          size="small"
          type="success"
          v-if="$canAction(['Sys_Cache_Edit'])"
          @click="openCacheEdit(row)"
        >
          编辑
        </Button>
        <Button
          class="ml2"
          size="small"
          type="info"
          v-if="$canAction(['Sys_Cache_Search'])"
          @click="openDrawer(row)"
        >
          查看
        </Button>
        <Button
          class="ml2"
          size="small"
          type="warning"
          v-if="$canAction(['Sys_Cache_Edit'])"
          @click="importCache(row)"
        >
          更新
        </Button>
        <Button
          class="ml2"
          size="small"
          type="error"
          v-if="$canAction(['Sys_Cache_Del'])"
          @click="showDelete(row)"
        >
          删除
        </Button>
      </template>
      <template slot="Status" slot-scope="{ row }">
        <Tag
          type="border"
          :color="
            row.Status === 10 ? 'green' : row.Status === 20 ? 'red' : 'default'
          "
        >
          {{ row.StatusText }}
        </Tag>
      </template>
    </Table>
    <!--    分页-->
    <Row class="mt10">
      <Col span="24" class="flex-end">
        <Page
          :page-size-opts="[40, 60, 80, 100]"
          :page-size="40"
          :total="resObj.TotalCount"
          :current="resObj.CurrentPage"
          @on-page-size-change="handleChangePageSize"
          @on-change="handleChangePage"
          size="small"
          show-elevator
          show-sizer
          show-total
        />
      </Col>
    </Row>
    <cache-edit @on-success="handleEditSuccess" ref="cacheEdit" />
    <detail-drawer ref="detailDrawer" />
  </Layout>
</template>
<script>
import layout from "@/components/layout/index.vue";
import api from "@/api";
import detailDrawer from "./components/cache-drawer.vue";
import cacheEdit from "./components/cache-edit.vue";
export default {
  name: "CacheManage",
  data() {
    return {
      tableLoading: false,
      tableHeight: 0,
      modalDelete: false, // 删除窗口状态
      DeleteLoading: false, // 删除按钮状态
      modalClear: false, // 清除缓存模态框状态
      modalImport: false,
      selectedRow: {},
      params: {
        CacheName: "",
        CacheKey: "",
        Status: null,
        Page: 1,
        PageSize: 40,
        SortName: "ID",
        SortOrder: "Asc",
      },
      columns: [
        {
          title: "编号",
          key: "ID",

          align: "center",
          sortable: "custom",
          minWidth: 65,
        },
        {
          title: "缓存名称",
          key: "CacheName",

          align: "center",
          minWidth: 100,
        },
        {
          title: "缓存编码",
          key: "CacheKey",

          align: "center",
          sortable: "custom",
          minWidth: 120,
        },
        {
          title: "缓存时间",
          key: "CacheTime",

          align: "center",
          sortable: "custom",
          minWidth: 90,
        },
        {
          title: "单位",
          key: "CacheUnitCN",

          align: "center",
          minWidth: 70,
        },
        {
          title: "状态",
          key: "Status",
          slot: "Status",
          align: "center",
          minWidth: 100,
          sortable: "custom",
        },
        {
          title: "缓存更新时间",
          key: "UpdateDate",
          minWidth: 135,

          align: "center",
          sortable: "custom",
        },
      ],
      resObj: {
        CurrentPage: 1,
        TotalCount: 0,
        TotalPage: 0,
        DataList: [],
      },
    };
  },
  computed: {},
  created() {
    if (this.$route.meta.tableActionWidth) {
      this.columns.push({
        title: "操作",
        key: "action",
        slot: "Action",
        align: "center",
        minWidth: this.$route.meta.tableActionWidth,
        maxWidth: this.$route.meta.tableActionWidth,
        fixed: "right",
      });
    }
    this.loadCacheList();
  },
  mounted() {
    const self = this;
    self.$nextTick(() => {
      self.tableHeight = self.$getTableHeight("main-container");
    });
    window.onresize = function () {
      self.$nextTick(() => {
        self.tableHeight = self.$getTableHeight("main-container");
      });
    };
    document.onkeydown = function (event) {
      var e = event || window.event;
      if (self.$route.name === self.$options.name && e && e.keyCode === 13) {
        // enter 键
        self.loadCacheList();
      }
    };
    this.$bus.$on("refreshPage", () => {
      if (this.$route.name === this.$options.name) {
        this.params && (this.params = this.$options.data().params);

        this.loadCacheList();
      }
    });
  },
  activated() {
    const self = this;
    window.addEventListener("keydown", function (event) {
      var e = event || window.event;
      if (self.$route.name === self.$options.name && e && e.keyCode === 13) {
        // enter 键
        self.loadCacheList();
      }
    });
  },
  deactivated() {
    window.removeEventListener("keydown", function () {});
  },
  methods: {
    openDrawer(row) {
      this.$refs.detailDrawer.obj.Key = row.CacheKey;
      this.$refs.detailDrawer.obj.Date = row.CreateDate;
      this.$refs.detailDrawer.isShow = true;
    },
    handleChangePageSize(pagesize) {
      this.params.Page = 1;
      this.params.PageSize = pagesize;
      this.loadCacheList();
    },
    handleChangePage(page) {
      this.params.Page = page;
      this.loadCacheList();
    },
    // 远程排序
    sortChange(result) {
      this.params.SortName = result.key;
      this.params.SortOrder = this.params.SortOrder === "Desc" ? "Asc" : "Desc";
      this.loadCacheList();
    },
    async loadCacheList() {
      this.tableLoading = true;
      try {
        const res = await api.GetDataCacheList(this.params);
        if (res.Status === 100) {
          this.resObj = res.Data;
          this.tableLoading = false;
        } else {
          this.$Message.error(res.Msg);
        }
      } catch (error) {
        this.$Notice.error({
          title: "错误",
          desc: error,
        });
        this.tableLoading = false;
        // TODO: 记录错误日志
      }
    },
    openCacheEdit(row) {
      this.$refs.cacheEdit.$refs.cacheForm.resetFields();
      this.$refs.cacheEdit.obj = JSON.parse(JSON.stringify(row)); // 去掉双向数据绑定
      this.$refs.cacheEdit.isShow = true;
    },
    async importCache(row) {
      await this.$importCache(row.CacheKey, null, this.loadCacheList);
    },
    openCacheAdd() {
      this.$refs.cacheEdit.$refs.cacheForm.resetFields();
      this.$refs.cacheEdit.obj = {}; // 去掉双向数据绑定
      this.$refs.cacheEdit.isShow = true;
    },
    // 修改成功，更改列表数据
    handleEditSuccess(res) {
      const index = this.resObj.DataList.findIndex(
        (item) => item.ID === res.ID
      );
      if (index >= 0) {
        this.resObj.DataList.splice(index, 1, res);
      } else {
        this.resObj.DataList.push(res);
      }
    },
    handleSearch() {
      this.params.Page = 1;
      this.loadCacheList();
    },
    handleReset() {
      this.$refs.SearchForm.resetFields();
      this.loadCacheList();
    },
    closeModal() {
      this.$Modal.remove();
    },
    showDelete(row) {
      this.selectedRow = row;
      this.$Modal.confirm({
        title: "删除缓存确认",
        closable: true,
        content: "缓存删除后不能恢复，确认删除吗?",
        onOk: this.deleteCache,
        cancelText: "取消",
        onCancel: () => {
          this.$Modal.remove();
        },
      });

      // this.modalDelete=true;
    },
    showClear(row) {
      this.selectedRow = row;
      this.$Modal.confirm({
        title: "清除缓存确认",
        closable: true,
        loading: true,
        content: "缓存清除后不能恢复，确认清除吗?",
        onOk: this.cleareCache,
        cancelText: "取消",
        onCancel: () => {
          this.$Modal.remove();
        },
      });
    },

    async deleteCache() {
      const _this = this;
      try {
        const res = await api.DeleteDataCache({
          id: this.selectedRow.ID,
        });
        if (res.Status === 100) {
          _this.closeModal();
          const index = _this.resObj.DataList.findIndex(
            (item) => item.ID === res.ID
          );
          _this.resObj.DataList.splice(index, 1);
          _this.$Message.success("我成功的帮您干掉了这条缓存~");
        } else {
          _this.$Message.error(res.Msg);
        }
      } catch (error) {
        this.$Notice.error({
          title: "错误",
          desc: error,
        });
        _this.closeModal();
      }
    },
    async cleareCache() {
      const _this = this;
      try {
        const res = await api.ClearDataCache({
          id: _this.selectedRow.CacheKey,
        });
        if (res.Status === 100) {
          _this.closeModal();
          _this.$Message.success("我成功的帮您清除了这条缓存~");
        } else {
          _this.$Message.error(res.Msg);
        }
      } catch (error) {
        _this.$Notice.error({
          title: "错误",
          desc: error,
        });
        _this.closeModal();
        // TODO: 记录错误日志
      }
    },
  },
  components: { cacheEdit, detailDrawer, layout },
};
</script>
